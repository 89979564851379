/* eslint-disable import/first */
/* eslint-disable global-require */

// Those polyfills are necessary for Samsung Internet 4 (Tizen 2018)
import 'core-js/features/promise/finally';
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import 'core-js/proposals/object-values-entries';
import 'core-js/features/object/get-own-property-descriptors';

import type {} from 'styled-components/cssprop';

import './context/PerformanceMonitorContext';

import React, { Suspense } from 'react';

import { isProductionEnvironment } from '@srgssr/rio-common';
import { registerRootComponent } from 'expo';

import { RedBackground } from './components/common/RedBackground';
import { GlobalStyle } from './globalStyles';
import { MANUFACTURER } from './utils/constants';
import { PerformanceMonitorStaticMarkers, monitoredLazy } from './utils/performanceMonitor';

performanceMonitor.markOnce(PerformanceMonitorStaticMarkers['bundle:init']);

/**
 * LG WebOS TV specific imports
 */

import '@procot/webostv/webOSTV';
import '@procot/webostv/webOSTV-dev';

if (MANUFACTURER === 'lg') {
  if (!isProductionEnvironment()) require('@procot/webostv/webOSTV-dev');
  require('@procot/webostv/webOSTV');
}

const App = monitoredLazy('app', () => import('./App').then((module) => ({ default: module.App })));

const Root = () => {
  performanceMonitor.markOnce(PerformanceMonitorStaticMarkers['react:init']);
  return (
    <>
      <GlobalStyle />
      <Suspense fallback={<RedBackground />}>
        <App />
      </Suspense>
    </>
  );
};

registerRootComponent(Root);
